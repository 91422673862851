.empty-catalog {
  text-align: center;
  width: 100%;
  height: calc(100vh - 6rem);
}
.empty-catalog cds-icon {
  margin-top: 2rem;
  width: 4rem;
  height: 4rem;
}
.empty-catalog cds-button {
  margin-top: 2rem;
}
.empty-catalog p {
  margin-top: 0.6rem;
}

.scrollHandler {
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 50vh;
}

.catalogContainer {
  padding-bottom: 5vh;
}

.endPageMessage {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
}

.kubeapps-main-container .content-area {
  overflow-y: scroll;
}
