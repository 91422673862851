.cds-textarea-fix {
  font: caption;
}

.ca-skip-tls {
  margin-top: -1em;
}

.ace-solarized-dark .ace_comment {
  font-style: normal;
}
