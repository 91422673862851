.app-repo-list-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.app-repo-list-header .refresh-all-button {
  margin: 0 0 0 0.6rem;
  display: inline;
}

.page-content {
  margin: 0 1.2rem 0 1.2rem;
}
