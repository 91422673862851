.angle {
  position: absolute;
  right: 15px;
  top: 1.2rem;
}

.kubeapps-dropdown {
  opacity: 0.75;
  padding-left: 0.5rem;
}
.kubeapps-dropdown:hover {
  opacity: 1;
  background-color: #25333d;
}
.kubeapps-dropdown.open {
  opacity: 1;
  background-color: #25333d;
}
.kubeapps-dropdown button {
  color: var(--cds-global-color-gray-50, #fafafa);
}

.kubeapps-dropdown-section {
  text-align: initial;
}

.kubeapps-dropdown-header {
  font-size: small;
}

.kubeapps-dropdown-text {
  margin-left: 0.2rem;
  margin-right: 0.7rem;
  font-weight: 500;
}

.header-actions .dropdown-menu {
  background-color: #25333d;
  width: 15rem;
  border: 0px;
  margin-right: 0px !important;
}
.header-actions .dropdown-menu .dropdown-menu-padding {
  padding: 0 1rem 0.6rem 1rem;
}
.header-actions .dropdown-menu .context-selector-header {
  color: var(--cds-global-color-gray-50, #fafafa);
  font-size: small;
  max-width: 100%;
  white-space: normal;
}
.header-actions .dropdown-menu .clr-select-wrapper {
  display: block;
}
.header-actions .dropdown-menu .clr-select-wrapper .clr-page-size-select {
  color: var(--cds-global-color-gray-50, #fafafa);
  width: 100%;
}

.kubeapps-create-new-ns {
  display: flex;
  justify-content: flex-end;
}
.kubeapps-create-new-ns cds-button {
  --color: var(--cds-global-color-gray-50, #fafafa);
  outline: 0px;
  margin-top: 1em;
}
