#search {
  color: var(--cds-global-typography-color-500, #000);
  margin-left: 0.3rem;
}

#search input::placeholder {
  color: var(--cds-global-typography-color-300, #454545);
}

header .search-box form {
  border-radius: 0.15rem;
  margin-left: 1rem;
}
