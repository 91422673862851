.diff {
  height: 500px;
  overflow: auto;
  margin-bottom: 0.6rem;
}

.diff pre {
  font: 15px/normal "Monaco", "Menlo", "Ubuntu Mono", "Consolas", "source-code-pro", monospace;
  line-height: 12px !important;
  border: none;
}
