.card-header .info-card-header {
  display: flex;
  justify-content: space-between;
}
.card-header .info-card-header .card-title {
  max-width: calc(100% - 24px);
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.card-header .info-card-header .card-tooltip {
  align-self: flex-end;
}

.bg-img {
  width: 0px;
  opacity: 0.05;
  position: relative;
  right: 80px;
  bottom: 10px;
}
.bg-img img {
  height: 100px;
}

.card-block {
  height: 4.5rem;
  overflow: hidden;
}
.card-block .info-card-block {
  display: flex;
}
.card-block .info-card-block .card-icon {
  display: flex;
  align-items: center;
  text-align: center;
  width: 64px;
  height: 64px;
}
.card-block .info-card-block .card-icon img {
  width: 64px;
  max-height: 64px;
}
.card-block .info-card-block .card-description-wrapper {
  display: grid;
  height: 64px;
  width: 100%;
  align-items: center;
}
.card-block .info-card-block .card-description-wrapper .card-description {
  max-height: 64px;
  padding-left: 0.6rem;
}

.kubeapps-card-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
}
.kubeapps-card-footer .kubeapps-card-footer-col1 {
  width: 100%;
  margin: 0 0.6rem 0 0.6rem;
}
.kubeapps-card-footer .kubeapps-card-footer-col2 {
  margin: 0 0.5rem 0 0;
}

.card.clickable a {
  text-decoration: none;
  color: var(--cds-global-typography-color-400, #333);
}

.footer-tags {
  text-align: right;
}
